import { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useRecoilValue, useRecoilState } from 'recoil'
import { pageState } from '../atoms/PageAtom'
import { currentPlayState } from '../atoms/CurrentPlayAtom'
import { playQueueState } from '../atoms/PlayQueueAtom'
import { playQueueOpenState } from '../atoms/PlayQueueOpenAtom'
import { walletState } from '../atoms/WalletAtom'

import PlayQueue from './PlayQueue'

type MusicData = {
  chain_id: number
  contract_address: string
  token_id: string
  image: string
  sound: string
  name: string
  artist_name: string
  artist_public_id: string
}

function PlayArea() {
  const [, setPage] = useRecoilState(pageState)
  const [currentPlay, setCurrentPlay] = useRecoilState(currentPlayState)
  const [playQueue, setPlayQueue] = useRecoilState(playQueueState)
  const [playQueueOpen, setPlayQueueOpen] = useRecoilState(playQueueOpenState)
  const walletAddress = useRecoilValue(walletState)

  const [musicData, setMusicData] = useState<MusicData>({
    chain_id: 1,
    contract_address: '',
    token_id: '',
    image: '',
    sound: '',
    name: '',
    artist_name: '',
    artist_public_id: '',
  })

  const [volumeIcon, setVolumeIcon] = useState(
    'https://d2tr0ey4qw5bcr.cloudfront.net/icons/vol-large.svg'
  )

  const [music, setMusic] = useState(new Audio())
  const [isPlay, setIsPlay] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [musicDuration, setMusicDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [isRepeat, setIsRepeat] = useState(false)
  const [isAudioFinish, setIsAudioFinish] = useState(false)

  const [volume, setVolume] = useState(1)
  const [volumeShow, setVolumeShow] = useState(false)

  useEffect(() => {
    clearPlayArea()
  }, [walletAddress])

  useEffect(() => {
    if (playQueue.length < 1) return setIsPlay(false)

    playMusic()

    return () => stop()
  }, [playQueue])

  useEffect(() => {
    if (!music.src) return

    play()

    return () => stop()
  }, [music])

  useEffect(() => {
    if (!isAudioFinish) return

    setIsAudioFinish(false)
    nextMusic()
  }, [isAudioFinish])

  useEffect(() => {
    music.loop = isRepeat
  }, [isRepeat])

  useEffect(() => {
    if (currentPlay.outerPause) pause()
  }, [currentPlay])

  const fixUriPrefix = (uri: string) => {
    let fixUri = String(uri)
    if (fixUri.indexOf('ipfs://') !== -1) {
      fixUri = fixUri.replace('ipfs://', 'https://ipfs.io/ipfs/')
    } else if (fixUri.indexOf('ar://') !== -1) {
      fixUri = fixUri.replace('ar://', 'https://arweave.net/')
    }

    return fixUri
  }

  const clearPlayArea = () => {
    stop()
    setIsPlay(false)
    setIsLoading(true)
    setMusicDuration(0)
    setCurrentTime(0)
    setMusic(new Audio())
    setPlayQueue([])
    setMusicData({
      chain_id: 1,
      contract_address: '',
      token_id: '',
      image: '',
      sound: '',
      name: '',
      artist_name: '',
      artist_public_id: '',
    })
  }

  const playMusic = () => {
    if (playQueue.length < 1) return setIsPlay(false)

    setCurrentTime(0)
    const target = playQueue[0]
    setMusicData({
      chain_id: target.chain_id,
      contract_address: target.contract_address,
      token_id: target.token_id,
      image: target.image,
      sound: target.sound,
      name: target.name,
      artist_name: target.artist_name,
      artist_public_id: target.artist_public_id,
    })

    setIsLoading(true)

    // 音楽再生
    if (!music.paused) stop()
    const audio = new Audio(fixUriPrefix(target.sound))
    audio.volume = volume

    audio.addEventListener('loadeddata', () => {
      setIsLoading(false)
      setMusicDuration(audio.duration)
    })
    setMusic(audio)

    // 再生イベントをGAに送信
    if (import.meta.env.VITE_STAGING === 'production')
      ReactGA.event({
        category: 'PlayMusic', // カテゴリー
        action: 'PlayMusic', // アクション
        label: `${target.name}`, // ラベル
      })
  }

  const play = async () => {
    if (playQueue.length < 1) return setIsPlay(true)

    await music.play()

    document.title = `${musicData.name} | ${musicData.artist_name}` // タイトルを書き換え

    setCurrentTime(music.currentTime)
    setCurrentPlay({
      chainId: musicData.chain_id,
      tokenId: musicData.token_id,
      contractAddress: musicData.contract_address,
      playStatus: true,
      outerPause: false,
    })

    setIsPlay(true)
    music.addEventListener('ended', () => setIsAudioFinish(true))
    music.addEventListener('timeupdate', (e: Event) => {
      const time = e.currentTarget ? e.currentTarget.currentTime : 0
      setCurrentTime(time)
    })
    music.addEventListener('waiting', (e: Event) => {
      console.log('loading')
    })
  }

  const pause = () => {
    music.pause()
    setCurrentPlay({
      chainId: musicData.chain_id,
      tokenId: musicData.token_id,
      contractAddress: musicData.contract_address,
      playStatus: false,
      outerPause: false,
    })
    setIsPlay(false)
  }

  const stop = () => {
    music.pause()
    music.src = ''
    setIsPlay(false)
    setCurrentTime(0)
  }

  const nextMusic = () => {
    if (playQueue.length <= 0) return
    if (!music.paused) stop()
    resetTimeLine()

    if (!isRepeat) {
      const current = playQueue[0]
      const copyPlayList = [...playQueue]
      copyPlayList.shift()
      copyPlayList.push(current)
      setPlayQueue(copyPlayList)
    }

    playMusic()
  }

  const prevMusic = () => {
    if (playQueue.length <= 0) return
    if (!music.paused) stop()
    resetTimeLine()

    if (!isRepeat) {
      const lastIndex = playQueue.length - 1
      const last = playQueue[lastIndex]
      const copyPlayList = [...playQueue]
      copyPlayList.pop()
      copyPlayList.unshift(last)
      setPlayQueue(copyPlayList)
    }

    playMusic()
  }

  // タイムライン
  const changeTimeLine = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = Number(e.currentTarget.value)

    setCurrentTime(time)
    music.currentTime = time
  }

  const resetTimeLine = () => {
    setCurrentTime(0)
    music.currentTime = 0
  }

  // シャッフル
  const shuffle = () => {
    if (playQueue.length <= 0) return
    if (confirm('現在再生中のキューをシャッフルします')) {
      const current = playQueue[0]
      const copyPlayList = [...playQueue]
      copyPlayList.shift()
      const shufflePlayList = copyPlayList.sort(() => Math.random() - 0.5)
      shufflePlayList.push(current)
      setPlayQueue(shufflePlayList)
    }
  }

  // ボリューム
  const changeVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.currentTarget.value)
    setVolume(value)
    music.volume = value

    if (value === 1) {
      setVolumeIcon('https://d2tr0ey4qw5bcr.cloudfront.net/icons/vol-large.svg')
    } else if (value < 1 && value >= 0.6) {
      setVolumeIcon(
        'https://d2tr0ey4qw5bcr.cloudfront.net/icons/vol-medium.svg'
      )
    } else if (value < 0.6 && value > 0) {
      setVolumeIcon('https://d2tr0ey4qw5bcr.cloudfront.net/icons/vol-small.svg')
    } else {
      setVolumeIcon('https://d2tr0ey4qw5bcr.cloudfront.net/icons/vol-mute.svg')
    }
  }

  const nftDetailPath = (
    chainId: number,
    contractAddress: string,
    tokenId: string
  ) => {
    let networkName = ''
    switch (chainId) {
      case 1:
        networkName = 'ethereum'
        break
      case 5:
        networkName = 'goerli'
        break
      case 137:
        networkName = 'polygon'
        break
    }

    return `nft/${networkName}/${contractAddress}/${tokenId}/detail`
  }

  return (
    <>
      <div className="play-area" id="playArea">
        <div className="preview" id="preview">
          <div
            className="cover"
            id="playCover"
            style={{ backgroundImage: `url(${musicData.image})` }}
            onClick={() =>
              setPage(
                nftDetailPath(
                  musicData.chain_id,
                  musicData.contract_address,
                  musicData.token_id
                )
              )
            }
          ></div>
          <div className="meta">
            <h2
              className="ttl"
              id="playTitle"
              onClick={() =>
                setPage(
                  nftDetailPath(
                    musicData.chain_id,
                    musicData.contract_address,
                    musicData.token_id
                  )
                )
              }
            >
              {musicData.name}
            </h2>
            <button
              className="creator"
              id="playCreator"
              onClick={() => setPage(`artists/${musicData.artist_public_id}`)}
            >
              {musicData.artist_name}
            </button>
          </div>
        </div>
        <div className="controller">
          <div className="buttons">
            <button
              className="btn shuffle"
              style={{
                backgroundImage: `url(https://d2tr0ey4qw5bcr.cloudfront.net/icons/shuffle.svg)`,
              }}
              onClick={() => shuffle()}
            ></button>
            <button
              className="btn prev"
              style={{
                backgroundImage: `url(https://d2tr0ey4qw5bcr.cloudfront.net/icons/prev.svg)`,
              }}
              onClick={() => prevMusic()}
            ></button>
            <button
              className={`btn circle ${isLoading ? 'loading' : ''}`}
              style={{
                backgroundImage: `url(https://d2tr0ey4qw5bcr.cloudfront.net/icons/${
                  isLoading ? 'circle-loading' : isPlay ? 'pause' : 'play'
                }.svg)`,
              }}
              onClick={() => {
                isPlay ? pause() : play()
              }}
            ></button>
            <button
              className="btn next"
              style={{
                backgroundImage: `url(https://d2tr0ey4qw5bcr.cloudfront.net/icons/next.svg)`,
              }}
              onClick={() => nextMusic()}
            ></button>
            <button
              className="btn repeat"
              style={{
                backgroundImage: `url(https://d2tr0ey4qw5bcr.cloudfront.net/icons/${
                  isRepeat ? 'repeat-red' : 'repeat'
                }.svg)`,
              }}
              onClick={() => setIsRepeat(!isRepeat)}
            ></button>
          </div>
          <div className="timeline-box" id="timelineBox">
            <input
              type="range"
              name="speed"
              min="0"
              max={musicDuration}
              className="timeline"
              id="timeline"
              value="0"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeTimeLine(e)
              }
            />
            <div
              className="timeline-current"
              id="timelineCurrent"
              style={{
                width: `${
                  musicDuration ? (currentTime / musicDuration) * 100 : '0'
                }%`,
              }}
            ></div>
          </div>
        </div>
        <div className="other">
          <button
            className="btn volume"
            style={{ backgroundImage: `url(${volumeIcon})` }}
            onClick={() => setVolumeShow(!volumeShow)}
          >
            <input
              type="range"
              className={`volume-range ${volumeShow ? 'show' : ''}`}
              min="0"
              max="1"
              step="0.1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeVolume(e)
              }
            />
          </button>
          <button
            className="btn list"
            style={{
              backgroundImage: `url(https://d2tr0ey4qw5bcr.cloudfront.net/icons/list.svg)`,
            }}
            onClick={() => setPlayQueueOpen(!playQueueOpen)}
          ></button>
        </div>
      </div>
      <PlayQueue />
    </>
  )
}

export default PlayArea
