import { useRecoilState } from 'recoil'
import { pageState } from '../atoms/PageAtom'
import { backButtonState } from '../atoms/BackButtonAtom'

function BackButton() {
  const [, setPage] = useRecoilState(pageState)
  const [backButton, setBackButton] = useRecoilState(backButtonState)

  return backButton.displayText ? (
    <button
      className="default-btn"
      onClick={() => {
        setPage(backButton.pageSlug)
        setBackButton({ displayText: '', pageSlug: '' })
      }}
    >
      <span
        className="icon"
        style={{
          backgroundImage: `url(https://d2tr0ey4qw5bcr.cloudfront.net/icons/arrow-left.svg)`,
        }}
      ></span>
      {backButton.displayText}
    </button>
  ) : (
    <span></span>
  )
}

export default BackButton
