import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import { playQueueState } from '../atoms/PlayQueueAtom'
import { playQueueOpenState } from '../atoms/PlayQueueOpenAtom'
import { pageState } from '../atoms/PageAtom'

const PlayQueue = () => {
  const { t } = useTranslation()

  const [playQueue, setPlayQueue] = useRecoilState(playQueueState)
  const [playQueueOpen, setPlayQueueOpen] = useRecoilState(playQueueOpenState)
  const [, setPage] = useRecoilState(pageState)

  const clickList = (e: React.MouseEvent<HTMLButtonElement>) => {
    const index = Number(e.currentTarget.dataset.index)
    if (index > playQueue.length - 1) return

    const copyPlayList = [...playQueue]
    const beforeList = copyPlayList.slice(index)
    const afterList = copyPlayList.slice(0, index)
    const lists = beforeList.concat(afterList)
    setPlayQueue(lists)
  }

  const networkName = (chainId: number): string => {
    let name = ''
    switch (chainId) {
      case 1:
        name = 'ethereum'
        break
      case 5:
        name = 'goerli'
        break
      case 137:
        name = 'polygon'
        break
    }
    return name
  }

  const fixUriPrefix = (uri: string) => {
    let fixUri = String(uri)
    if (fixUri.indexOf('ipfs://') !== -1) {
      fixUri = fixUri.replace('ipfs://', 'https://ipfs.io/ipfs/')
    } else if (fixUri.indexOf('ar://') !== -1) {
      fixUri = fixUri.replace('ar://', 'https://arweave.net/')
    }

    return fixUri
  }

  const clickOption = (e: React.MouseEvent<HTMLButtonElement>) => {
    const index = Number(e.currentTarget.dataset.index)
    if (index > playQueue.length - 1) return
    setPage(
      `nft/${networkName(playQueue[index].chain_id)}/${
        playQueue[index].contract_address
      }/${playQueue[index].token_id}/detail`
    )
    setPlayQueueOpen(false)
  }

  const lists = playQueue.map((list, key) => {
    return (
      <div className="list" key={key}>
        <div
          className="clckable"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => clickList(e)}
          data-index={key}
        >
          <p className="number">{key !== 0 ? key : 'now'}</p>
          <div
            className="image"
            style={{ backgroundImage: `url(${fixUriPrefix(list.image)})` }}
          ></div>
          <div className="detail">
            <h2 className="title">{list.name}</h2>
            <h2 className="artist">creator: {list.artist_name}</h2>
          </div>
        </div>
        <button
          className="btn"
          style={{
            backgroundImage: `url(https://d2tr0ey4qw5bcr.cloudfront.net/icons/file-line.svg)`,
          }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => clickOption(e)}
          data-index={key}
        ></button>
      </div>
    )
  })

  return (
    <div className={`play-queue ${playQueueOpen ? 'open' : ''}`}>
      <div className="ttl-box">
        <h2 className="ttl">{t('components.PlayQueue.title')}</h2>
        <button
          className="btn down"
          style={{
            backgroundImage: `url(https://d2tr0ey4qw5bcr.cloudfront.net/icons/arrow-down.svg)`,
          }}
          onClick={() => setPlayQueueOpen(false)}
        ></button>
      </div>
      <div className="queue-box">
        <p className="desc">
          {playQueue.length}
          {t('components.PlayQueue.description')}
        </p>
        <div className="lists">{lists}</div>
      </div>
    </div>
  )
}
export default PlayQueue
